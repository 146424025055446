































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































i:hover {
  cursor: pointer;
}
.title {
  text-align: center;
  margin-bottom: 15px;
}
.flex {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-items: self-start;
  
}
.container {
}
